<template>
    <div class="sub-content part">
        <div class="heading" v-if="title">{{ title }}</div>
        <span v-if="description!==null" v-html="description"></span>
        <GalleryComponent v-if="sliderImages!=null" :slider-images="sliderImages" />
        
        <Accordeon v-if="accordionItems.length > 0" :items="accordionItems" class="mt20" />
        
    </div>
</template>

<script>
import Accordeon from '@/components/details/accordeon.vue';
import GalleryComponent from '@/components/details/gallery.vue';
import { getFieldValues, json_decode } from '@/utils/helpers';

export default {
    name: "SubContent",  
    components: {
        Accordeon,
        GalleryComponent
    },
    props: {
        content: {
            type: [Object, String],
            required: true,
        }, 
    },
    computed: {
        title(){
            return getFieldValues(this.content,'title');
        },
        description(){
            if (typeof this.content === 'string') {
                return this.content;
            }
            return getFieldValues(this.content,'body');
        },
        accordionItems(){
            if (typeof this.content === 'string') {
                return [];
            }
            var items = getFieldValues(this.content,'akkordeon');
            if(!items || !Array.isArray(items)) {
                items = [items];
            }

            const accordionItems = [];
            items.forEach(item => {
                var title = getFieldValues(item,'title');
                var content = getFieldValues(item,'body');
                if(title && content) {
                    accordionItems.push({
                        title: title,
                        content: content
                    });
                }
            });
            return accordionItems;
        },
        sliderImages(){
            if (typeof this.content === 'string') {
                return null;
            }
            var teaserImgs = [];
            var images = getFieldValues(this.content, 'bilder');
            if(images !== null && Array.isArray(images)){
                for(var i = 0; i < images.length; i++){
                    var image = json_decode(images[i]);
                    if(typeof image === "object"){
                        teaserImgs.push({image:this.$backendUrl+image.path,class:'teaser-img', copyright:image.copyright,description:image.description});
                    }
                    else {
                        teaserImgs.push({image:this.$backendUrl+image,class:'teaser-img', copyright:null,description:null});  
                    }
                }
            }
            else if(typeof images === 'string' || images instanceof String){
                var img = json_decode(images);
                teaserImgs.push({image:this.$backendUrl+img.path,class:'teaser-img', copyright:img.copyright,description:img.description});
            }
            return teaserImgs;
        },
        imgs(){
            if(this.sliderImages.length > 0){
                var lightBoxImages = [];
                for(var i= 0; i<this.sliderImages.length; i++){
                    lightBoxImages.push({src: this.sliderImages[i].image,alt:this.sliderImages[i].description})
                }
                return lightBoxImages;
            }
            return null;
        },
    },
    methods: {
        getBackgroundImage(image){
            return 'background-image: url("' + image + '")';
        },
        showLightbox(index){
            this.lightBoxVisible = true;
            this.lightBoxIndex = index;
        },
        hideLightbox(){
            this.lightBoxVisible = false;
        },
    },
}
</script>

<style lang="scss" scoped>

.heading{
    margin: 10px 0px 5px;
    font-size: 14px;
    font-weight: 700;
    font-family: 'elliot-bold', sans-serif;
}

.part{
    margin-bottom: 20px;
}

.profile-image {
    position: relative;
    z-index: 1;
    
    
    &-placeholder {
        height: 116px;
        background-repeat: no-repeat;  
        background-position: center center; 
        background-size: cover;
        &.logo{
            background-size: contain!important;
        }
    }
}

</style>

<style lang="scss">
.sub-content .splide-carousel .slide{
    padding: 10px;
    
}
</style>
