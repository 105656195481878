<template>
    <div class="accordion">
        <div v-for="(item, index) in items" :key="index" class="accordion-item">
            <div 
            class="accordion-header" 
            @click="toggleItem(index)"
            :class="{ 'active': activeIndex === index }"
            >
            <h3 class="part-title">{{ item.title }}</h3>
            <span class="icon">
                {{ activeIndex === index ? '−' : '+' }}
            </span>
        </div>
        <transition name="accordion">
            <div 
            v-if="activeIndex === index" 
            class="accordion-content"
            v-html="item.content"
            ></div>
        </transition>
    </div>
</div>
</template>

<script>
export default {
    name: 'Accordeon',
    props: {
        items: {
            type: Array,
            required: true,
            // Expected format: [{title: string, content: string}]
        }
    },
    data() {
        return {
            activeIndex: null
        }
    },
    methods: {
        toggleItem(index) {
            this.activeIndex = this.activeIndex === index ? null : index
        }
    }
}
</script>

<style lang="scss" scoped>
.accordion {
    width: 100%;
    background: transparent;
    
    &-item {
        border-bottom: 1px solid #e0e0e0;
        
        &:first-child {
            border-top: 1px solid #e0e0e0;
        }
    }
    
    &-header {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        background-color: transparent;
        transition: background-color 0.3s ease;
        
        &:hover {
            background-color: #f5f5f5;
        }
        
        &.active {
            background-color: #f5f5f5;
        }
        
        h3 {
            color: #000;
            font-size: 14px;
            font-weight: 700;
            font-family: 'elliot-bold', sans-serif;
        }
        
        .icon {
            font-size: 1.5rem;
            line-height: 1;
        }
    }
    
    &-content {
        padding: 1rem;
        background-color: #fff;
    }
}

// Transition animations
.accordion-enter-active {
    transition: max-height 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
    overflow: hidden;
}

.accordion-enter-from,
.accordion-leave-to {
    max-height: 0;
    opacity: 0;
}

.accordion-enter-to,
.accordion-leave-from {
    max-height: 500px;
    opacity: 1;
}
</style>
